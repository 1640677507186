/** @format */

import React from "react";
import "./loading.less";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
export default class Loading extends React.Component {
	render() {
		return (
			<div className='Loading'>
				<Box sx={{ width: "50%", margin: "0 auto", alignSelf: "center" }}>
					<h1>Loading</h1>
					<br />
					<LinearProgress />
				</Box>
			</div>
		);
	}
}
